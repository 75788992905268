import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Typography,
  List,
  ListItem,
  alpha,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

const NavItem = ({ title, items }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const hasActiveLink = () =>
    items.some((i) => i.href === activeLink || (i.subItems && i.subItems.find((sub) => sub.href === activeLink)));

  const renderSubItems = (subItems) => (
    <List disablePadding sx={{ paddingLeft: 2 }}>
      {subItems.map((subItem, index) => (
        <ListItem key={index} disablePadding>
          <Button
            component="a"
            href={subItem.href}
            fullWidth
            sx={{
              justifyContent: 'flex-start',
              color:'red',
              backgroundColor:
                activeLink === subItem.href
                  ? alpha(theme.palette.primary.main, 0.1)
                  : 'transparent',
              fontWeight: activeLink === subItem.href ? 600 : 400,
              pl: 4, // Indent for sub-items
            }}
          >
            {subItem.title}
          </Button>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        sx={{ backgroundColor: 'transparent' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
          sx={{ padding: 0 }}
        >
          <Typography
            fontWeight={hasActiveLink() ? 600 : 400}
            color={hasActiveLink() ? 'text.primary' : 'text.primary'}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={1}>
            {items.map((item, index) => (
              <Grid item key={index} xs={12}>
                {item.onClick ? (
                  <Button
                    onClick={item.onClick}
                    fullWidth
                    sx={{
                      justifyContent: 'flex-start',
                      color: theme.palette.text.primary,
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                    }}
                  >
                    {item.title}
                  </Button>
                ) : (
                  <>
                    <Button
                      component="a"
                      href={item.href}
                      fullWidth
                      sx={{
                        justifyContent: 'flex-start',
                        color:
                          activeLink === item.href
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        backgroundColor:
                          activeLink === item.href
                            ? alpha(theme.palette.primary.main, 0.1)
                            : 'transparent',
                        fontWeight: activeLink === item.href ? 600 : 400,
                      }}
                    >
                      {item.title}
                    </Button>
                    {item.subItems && renderSubItems(item.subItems)}
                  </>
                )}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default NavItem;