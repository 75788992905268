import React, { useContext,useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Page from '../components/Page';
import Main from '../../../layouts/Main';
import { UserContext, api, helpers } from '../../../utils';
import { ImageInput } from '../../../components';
import { ToastContainer, toast as Alert } from 'react-toastify';
import imageCompression from 'browser-image-compression';
import { Avatar } from '@mui/material';

function ProfilePhotoUpload(props) {
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState(userCtx.currentUser);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        getUser();
      }, []);

const getUser = async () => {
    try {
      const response = await api.fetch(`UserAdmin/User/${user.id}`);
      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

    async function handleSubmit(e) {
        e.stopPropagation();
        e.preventDefault();
        if (files && files.length) {
            // Image Compression Options
            const options = {
                maxSizeMB:.3, // Maximum file size (in MB)
                maxWidthOrHeight: 1920, // Maximum width or height of the image
                useWebWorker: true,
            };

            try {
                // Compress the image
                const compressedFile = await imageCompression(files[0], options);

                // Create FormData and append compressed file
                let payload = helpers.generateFormData('UserPhoto', compressedFile);
                payload.append('UserId', user.id);

                // API Call for uploading the image
                api.post_form_data('Public/SaveUserProfilePhoto', payload)
                    .then((response) => {
                        console.log('upload response!', response);
                        if (response?.data && response.data.success) {
                            Alert.success('Your profile has been updated successfully!');
                            setUser(response.data.message);
                            setFiles([]);
                        }
                    });
            } catch (error) {
                console.error(error);
                Alert.error('Error compressing image.');
            }
        }
    }

  return (
    <Main>
      <Page>
        <Box>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"space-between"}
            alignItems={{ xs: "flex-start", md: "center" }}
          >
            <Typography color='white' variant="h6" fontWeight={700}>
              Change Profile Photo
            </Typography>
          </Box>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              
              <Grid item xs={6}>
              <Avatar
                    alt="User Avatar"
                    variant="rounded"
                    src={user.photoUrl}
                    sx={{
                      borderRadius: "30px",
                      width: { xs: "10em", md: "12em" },
                      height: { xs: "10em", md: "12em" },
                    }}
                  />
              </Grid>
              <Grid item xs={6}>
                <ImageInput maxFileLimit={1} onDropCallback={setFiles} />
              </Grid>
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"flex-end"}
                  width={1}
                  margin={"0 auto"}
                >
                  <Button  component={'a'}
                        variant="contained"
                        color="customColor"
                        size="large" type={"submit"}
                        onClick={handleSubmit}>
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Page>
    </Main>
  );
}

export default ProfilePhotoUpload;
