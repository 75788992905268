import React, { useState, useContext, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import _ from "lodash";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./theme/index.css";
import ProductOverview from "./demos/ecommerce/views/ProductOverview/ProductOverview";
import "aos/dist/aos.css";
import { ToastContainer, toast as Alert } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api, constants, helpers, UserContext, storage } from "./utils";
import { Footer } from "./layouts";
import {
    Admin,
    Home,
    UserProfile,
    Page,
    SignIn,
    User,
    MassUserCreate,
    Users,
    ReferenceDataList,
    Research,
    ResetPassword,
    ForgotPassword,
    UpdatePassword,
    HowToHelp,
    Profile,
} from "./components";
import {
    UserThemeEdit,
    UserThemeArticleEdit,
    UserThemeStatsEdit,
    AccountQRCode,
    AccountGeneral,
    AccountSecurity,
    AccountNotifications,
    AboutSideCover,
    BlogNewsroom,
    BlogReachView,
    CompanyTerms,
    ContactPageCover,
    Customers,
    DesktopApp,
    Ecommerce,
    Faq,
    IndexView,
    NotFoundCover,
    PasswordResetCover,
    Pricing,
    ServerError,
    SignupCover,
    SigninCover,
    SignUpCoverCopy
} from "./views";
import QrEdit from "./components/QrEdit";
import { ProfilePhotoUpload } from "./views/Account";
import { Wallet } from "./views/index";
import ActivateUser from "./views/SignupCover copy/ActivateUser";
import Leads from "./views/Leads/Leads";
import Dashboard from "./components/Dashboard/views/pages/dashboards/Dashboard";
import { AdminDashboard } from "./views/Admin";
import UserCompanyTheme from "./components/UserCompanyTheme";
import UserCompanyThemeEdit from "./views/Account/UserCompanyThemeEdit/UserCompanyThemeEdit";
import CompanyLeads from "./views/CompanyLeadList/CompanyLeads";
import CompanyQRCode from "./views/Account/CompanyQRCode";
import Default from "./components/default";
import UserProfile2 from "./components/UserProfile2";

const nowTime = () => new Date().getTime();
const { THEMES } = constants;

export default function App() {
    const tokenFromStorage = api.userToken() || null;
    const parsedUser = tokenFromStorage
        ? JSON.parse(storage.getItem("currentUser"))
        : null;
    const [authToken, setAuthToken] = useState(tokenFromStorage);
    const [currentUser, setCurrentUser] = useState(parsedUser);
    const [impersonating, setImpersonating] = useState(false);
    const [theme, setTheme] = useState(THEMES.default);
    const [alertMessage, setAlertMessage] = useState(null);
    const navigate = useNavigate();

    // Token check every 5 minutes
    useEffect(() => {
        const interval = setInterval(() => {
            if (!authToken || !currentUser) {
                signOut("Session expired. Please log in again.");
            }
        }, 300000); // 5 minutes

        return () => clearInterval(interval);
    }, [authToken, currentUser]);

    function isAuthenticated() {
        return authToken !== null;
    }

    function enhanceUser(u) {
        if (!u) return u;
        if (u.token) {
            delete u.token;
        }
        return u;
    }

    function signOut(message) {
        if (!impersonating) {
            storage.removeItem("token");
            storage.removeItem("currentUser");
            storage.removeItem("lastActivity");
            storage.removeItem("sessionStartedAt");
            setAuthToken(null);
            setCurrentUser(null);
            if (message) {
                Alert.error(message);
            }
            navigate("/signin");
        } else {
            clearImpersonation();
            navigate("/");
        }
    }

    function clearImpersonation() {
        if (!impersonating) return;
        const adminUser = JSON.parse(storage.getItem("adminUser"));
        const adminUserToken = storage.getItem("adminToken");
        storage.removeItem("adminToken");
        storage.removeItem("adminUser");
        storage.setItem("token", adminUserToken);
        storage.setItem("currentUser", JSON.stringify(adminUser));
        setAuthToken(adminUserToken);
        setCurrentUser(adminUser);
        setImpersonating(false);
    }

    function impersonate(user, impersonateToken) {
        if (impersonating) return;
        const adminUser = JSON.parse(storage.getItem("currentUser"));
        const adminUserToken = storage.getItem("token");

        storage.removeItem("token");
        storage.removeItem("currentUser");
        user = enhanceUser(user);
        storage.setItem("token", impersonateToken);
        storage.setItem("currentUser", JSON.stringify(user));
        storage.setItem("adminToken", adminUserToken);
        storage.setItem("adminUser", JSON.stringify(adminUser));
        setAuthToken(impersonateToken);
        setCurrentUser(user);
        setImpersonating(true);
        navigate("/dashboard");
    }

    const user = {
        currentUser: currentUser,
        theme: theme,
        setTheme: setTheme,
        signOut: signOut,
        signIn: (newUser, token) => {
            if (token) {
                storage.setItem("token", token);
                setAuthToken(token);
            }
            storage.setItem("currentUser", JSON.stringify(newUser));
            setCurrentUser(newUser);
        },
        impersonate: impersonate,
        clearImpersonation: clearImpersonation
    };

    return (
        <div className={`${theme} siteContainer fullHeight`}>
            <UserContext.Provider value={user}>
                <Page>
                    <div>
                        <Routes>
                            <Route path="/about-side-cover" element={<AboutSideCover />} />
                            <Route path="/activate/:id/:activationCode/:profileId" element={<ActivateUser />} />
                            <Route path="/blog-newsroom" element={<BlogNewsroom />} />
                            <Route path="/company-terms" element={<CompanyTerms />} />
                            <Route path="/contact-page-cover" element={<ContactPageCover />} />
                            <Route path="/customers" element={<Customers />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/data" element={<DesktopApp />} />
                            <Route path="/e-commerce" element={<IndexView />} />
                            <Route exact path="/" element={<Ecommerce />} />
                            <Route path="/faq" element={<Faq />} />
                            <Route path="/forgot_password" element={<PasswordResetCover />} />
                            <Route path="/howtohelp" element={<HowToHelp />} />
                            <Route path="/login" element={<SigninCover />} />
                            <Route path="/notfound" element={<NotFoundCover />} />
                            <Route path="/passwordreset" element={<PasswordResetCover />} />
                            <Route exact path="/profile/:id" element={<Profile />} />
                            <Route exact path="/profile/:id/:profileNumber" element={<Profile />} />
                            <Route path="/pricing" element={<Pricing />} />
                            <Route path="/product" element={<ProductOverview />} />
                            <Route path="/research" element={<Research />} />
                            <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/signin" element={<SigninCover />} />
                            <Route exact path="/signin-cover" element={<SigninCover />} />
                            <Route path="/signup" element={<SignupCover />} />
                            <Route path="/error" element={<NotFoundCover />} />
                            <Route path="/profile-test" element={<BlogReachView />} />
                            <Route path="/default" element={<Default />} />
    
                            {/* AUTH ROUTES */}
                            <Route path="/leads" element={authToken ? <Leads /> : <Navigate to="/signin" />} />
                            <Route path="/account/theme" element={authToken ? <UserThemeEdit /> : <Navigate to="/signin" />} />
                            <Route path="/account/company/theme" element={authToken ? <UserCompanyThemeEdit /> : <Navigate to="/signin" />} />
                            <Route path="/account/theme/articles" element={authToken ? <UserThemeArticleEdit /> : <Navigate to="/signin" />} />
                            <Route path="/account/theme/stats" element={authToken ? <UserThemeStatsEdit /> : <Navigate to="/signin" />} />
                            <Route path="/change-profile-photo" element={authToken ? <ProfilePhotoUpload /> : <Navigate to="/signin" />} />
                            
                            {/* Unique Routes for User Profile */}
                            <Route path="/userprofile" element={authToken && currentUser && currentUser.companyId ? <UserProfile /> : <Navigate to="/signin" />} />
                            <Route path="/wallet" element={authToken && currentUser && currentUser.companyId ? <Wallet /> : <Navigate to="/signin" />} />
                            <Route path="/account/qrcode" element={authToken && currentUser && currentUser.companyId ? <AccountQRCode /> : <Navigate to="/signin" />} />
                            <Route path="/account/general" element={authToken && currentUser && currentUser.companyId ? <AccountGeneral /> : <Navigate to="/signin" />} />
                            <Route path="/account/apps" element={authToken && currentUser && currentUser.companyId ? <AccountNotifications /> : <Navigate to="/signin" />} />
                            <Route path="/account/security" element={authToken && currentUser && currentUser.companyId ? <AccountSecurity /> : <Navigate to="/signin" />} />
                            <Route path="/account/companyprofile" element={authToken && currentUser && currentUser.companyId ? <UserCompanyTheme /> : <Navigate to="/signin" />} />
    
                            {/* Role and Company ID Protected Routes */}
                            <Route
                                path="/admin/companyview"
                                element={
                                    authToken && currentUser ? (
                                    // Log currentUser before redirecting
                                    console.log('Current User:', currentUser) || (
                                        currentUser.companyId && currentUser.roleTypeIdList?.some((roleTypeId) => [1, 6].includes(roleTypeId)) 
                                        ? (<AdminDashboard />
                                        ) : (
                                        <Navigate to="/" />
                                        )
                                    )
                                    ) : (
                                    <Navigate to="/signin" />
                                    )
                                }
                                />
                            <Route
                                path="/admin/leads"
                                element={
                                    authToken && currentUser && currentUser.companyId && currentUser.roleTypeIdList?.some((roleTypeId) => [1, 6].includes(roleTypeId))
                                    ? <CompanyLeads />
                                    : <Navigate to="/" />
                                }
                                />
                            
                            <Route
                                path="/admin/users"
                                element={
                                    authToken && currentUser && currentUser.companyId && currentUser.roleTypeIdList?.some((roleTypeId) => [1, 6].includes(roleTypeId))
                                    ? <Users />
                                    : <Navigate to="/" />
                                }
                                />
                            <Route
                                path="/admin/massusercreate"
                                element={
                                    authToken && currentUser && currentUser.companyId && currentUser.roleTypeIdList?.some((roleTypeId) => [1, 6].includes(roleTypeId))
                                    ? <MassUserCreate />
                                    : <Navigate to="/" />
                                }
                                />
                            <Route
                                path="/user/:id"
                                element={
                                    authToken && currentUser && currentUser.companyId && currentUser.roleTypeIdList?.some((roleTypeId) => [1, 6].includes(roleTypeId))
                                    ? <User />
                                    : <Navigate to="/" />
                                }
                                />

                                <Route
                                path="/admin/qrcode"
                                element={
                                    authToken && currentUser && currentUser.companyId && currentUser.roleTypeIdList?.some((roleTypeId) => [1, 6].includes(roleTypeId))
                                    ? <CompanyQRCode />
                                    : <Navigate to="/" />
                                }
                                />
                        </Routes>
                    </div>
                </Page>
                <Footer />
                <ToastContainer />
            </UserContext.Provider>
        </div>
    );
}