import React, { useState } from "react";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row, Alert } from "reactstrap";
import { api } from "../utils";
import { DesktopContainer } from "../layouts";

export default function MassCreateUsers() {
  const [count, setCount] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMassCreate = async () => {
    if (!count || !companyId || count < 1) {
      setMessage({ text: "Please enter a valid count and company ID.", flavor: "alert-danger" });
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const response = await api.post("UserAdmin/MassCreateUsers", {
        count: parseInt(count, 10),
        companyId: parseInt(companyId, 10),
      });

      if (response.data) {
        setMessage({ text: `Successfully created ${count} users for Company ID ${companyId}.`, flavor: "alert-success" });
      } else {
        setMessage({ text: "Failed to create users.", flavor: "alert-danger" });
      }
    } catch (error) {
      console.error("Error creating users:", error);
      setMessage({ text: "An error occurred while creating users.", flavor: "alert-danger" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DesktopContainer screenName="Mass Create Users">
      <Row className="justify-content-center">
        <Col xs="6">
          <Card>
            <CardBody>
              <h4 className="mb-4">Mass Create Users</h4>

              {message && <Alert className={message.flavor}>{message.text}</Alert>}

              <FormGroup>
                <Label for="companyId">Company ID</Label>
                <Input
                  type="number"
                  name="companyId"
                  id="companyId"
                  value={companyId}
                  onChange={(e) => setCompanyId(e.target.value)}
                  placeholder="Enter Company ID"
                />
              </FormGroup>

              <FormGroup>
                <Label for="count">Number of Users</Label>
                <Input
                  type="number"
                  name="count"
                  id="count"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                  placeholder="Enter number of users"
                />
              </FormGroup>

              <Button color="primary" onClick={handleMassCreate} disabled={loading}>
                {loading ? "Creating..." : "Create Users"}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </DesktopContainer>
  );
}