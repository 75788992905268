/* eslint-disable indent */ /* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  TextField,
  Box,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import DebounceInput from "react-debounce-input";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { default as QrEdit } from "./QrEdit";
import { api, helpers, UserContext, constants } from "../utils";
import _ from "lodash";

const TABS = {
  DETAILS: "Profile Details",
  PASSWORD: "Credentials",
};

export default function UserArticles(props) {
  const { id } = useParams();
  const theme = useTheme();
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const [activeTab, setActiveTab] = useState(TABS.DETAILS);
  const [timezoneList, setTimezoneList] = useState([]);
  const [source, setSource] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [title, setTitle] = useState("");
  const [contentUrl, setContentUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [description, setDescription] = useState("");
  const [display, setDisplay] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  // Sample articles data, replace this with your actual articles data from state or API
  const [userProfileArticles, setUserProfileArticles] = useState([
    {
      id: 1,
      article: {
        title: "Article 1",
        thumbnailUrl: "Thumbnail 2",
        linkUrl: "URL 1",
        description: "Description 2",
      },

      display: true,
    },
    {
      id: 2,
      article: {
        title: "Article 1",
        thumbnailUrl: "Thumbnail 2",
        linkUrl: "URL 1",
        description: "Description 2",
      },

      display: true,
    },
    {
      id: 3,
      article: {
        title: "Article 1",
        thumbnailUrl: "Thumbnail 2",
        linkUrl: "URL 1",
        description: "Description 2",
      },

      display: true,
    },
  ]);

  // Function to handle checkbox change
  const handleCheckboxChange = (articleId) => {
    setUserProfileArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? { ...article, display: !article.display }
          : article
      )
    );
  };

  const getUserArticles = async () => {
    try {
      const response = await api.post(
        `profile/CurrentUserArticleList/${user.id}`,
        {}
      );
      if (response.data) {
        // Update state for all articles at once
        setUserProfileArticles(response.data);

        console.log(response.data, "response"); // Verify if you're getting the correct data
        console.log(userProfileArticles, "userArticles"); // Verify if you're getting the correct data
        console.log(userProfileArticles.data.title, "title"); // Verify if you're getting the correct data

        // No need to set state for each article individually here
      }
    } catch (error) {
      console.error("Failed to fetch user articles", error);
    }
  };
  async function saveUserArticles() {
    try {
      console.log("Preparing to save articles...");
  
      // Validate user exists
      if (!user || !user.id) {
        console.error("User ID is missing!");
        showMessage("User ID is missing. Cannot save articles.", "alert-danger");
        setOpenSnackbar(true);
        return;
      }
  
      // Build the payload
      let payload = userProfileArticles.map((article) => ({
        id: article.id,
        article: {
          id: article.article?.id,
          title: _.trim(article.article?.title || ""),
          linkUrl: _.trim(article.article?.linkUrl || ""),
          thumbnailUrl: _.trim(article.article?.thumbnailUrl || ""),
          description: _.trim(article.article?.description || ""),
        },
        userId: user.id,
        display: article.display ?? true,
      }));
  
      console.log("Payload being sent:", JSON.stringify(payload, null, 2));
  
      // Send API request
      const response = await api.post(`Profile/SaveUserArticle/${user.id}`, payload);
      
      console.log("API Response Received:", response);
  
      // Ensure response structure is correct
      if (response?.data?.success) {
        console.log("Articles saved successfully!");
        showMessage("Articles saved successfully!", "alert-success");
      } else {
        console.warn("API responded with failure:", response.data);
        showMessage(response.data?.message || "Articles save failed.", "alert-danger");
      }
      
      // Ensure Snackbar opens
      setOpenSnackbar(true);
  
    } catch (error) {
      console.error("Error saving articles:", error);
  
      // Handle API error properly
      const errorMessage =
        error.response?.data?.message || "Failed to save articles. Please try again.";
  
      showMessage(errorMessage, "alert-danger");
      setOpenSnackbar(true);
    }
  }

  const showMessage = (text, type) => {
    setMessage({ text, flavor: type });
    setOpenSnackbar(true); // Open the Snackbar whenever a new message is set
  };

  useEffect(() => {
    if (message) {
      setOpenSnackbar(true);
    }
  }, [message]);

  useEffect(() => {
    getUserArticles();
  }, []);

  return (
    <form>
      <Box color="white">
        {message && (
          <Box
            sx={{
              backgroundColor:
                message.flavor === "alert-success" ? "green" : "red",
              color: "white",
              padding: 2,
              marginBottom: 2,
              textAlign: "center",
              borderRadius: "5px",
            }}
          >
            {message.text}
          </Box>
        )}
        <Box color="white" marginBottom={2}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              color: "white !important", // Ensure white color with !important
            }}
          >
            Articles
          </Typography>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          sx={{ color: "white" }} // Set Snackbar text color to white
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={message?.flavor === "alert-danger" ? "error" : "success"}
            sx={{ width: "100%", color: "white" }} // Set Alert text color to white
          >
            {message?.text}
          </Alert>
        </Snackbar>
        {userProfileArticles.map((article) => (
          <Box key={article.id}>
            <Box
              component={Accordion}
              padding={1}
              borderRadius={`${theme.spacing(1)} !important`}
              sx={{
                "&::before": {
                  display: "none",
                },
              }}
            >
              <Box
                component={AccordionSummary}
                aria-controls="panel1a-content"
                id={`panel1a-header`}
              >
                <Typography fontWeight={600} sx={{ color: "white" }}>
                  {article.article.title}
                </Typography>
              </Box>
              <AccordionDetails>
                <Grid container spacing={4}>
                  <Grid item xs={12} display="flex">
                    <Typography
                      variant="subtitle2"
                      sx={{ marginBottom: 2, color: "white" }}
                      fontWeight={700}
                    >
                      Show Post?
                    </Typography>
                    <input
                      type="checkbox"
                      checked={article.display}
                      onChange={() => handleCheckboxChange(article.id)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      sx={{ marginBottom: 2, color: "white" }}
                      fontWeight={700}
                    >
                      Give your post a title
                    </Typography>
                    <TextField
                      style={{ marginBottom: 30 }}
                      label="Title *"
                      variant="outlined"
                      name="title"
                      fullWidth
                      value={article.article.title}
                      onChange={(e) => {
                        const updatedArticles = userProfileArticles.map((a) => {
                          if (a.id === article.id) {
                            return {
                              ...a,
                              article: {
                                ...a.article,
                                title: e.target.value,
                              },
                            };
                          }
                          return a;
                        });
                        setUserProfileArticles(updatedArticles);
                      }}
                      InputProps={{ style: { color: "white" } }} // Input text color
                      InputLabelProps={{ style: { color: "white" } }} // Label color
                    />

                    <TextField
                      style={{ marginBottom: 30 }}
                      label="Content Url *"
                      variant="outlined"
                      name="contentUrl"
                      fullWidth
                      value={article.article.linkUrl}
                      onChange={(e) => {
                        const updatedArticles = userProfileArticles.map((a) => {
                          if (a.id === article.id) {
                            return {
                              ...a,
                              article: {
                                ...a.article,
                                linkUrl: e.target.value,
                              },
                            };
                          }
                          return a;
                        });
                        setUserProfileArticles(updatedArticles);
                      }}
                      InputProps={{ style: { color: "white" } }} // Input text color
                      InputLabelProps={{ style: { color: "white" } }} // Label color
                    />

                    <TextField
                      style={{ marginBottom: 30 }}
                      label="Thumbnail Url *"
                      variant="outlined"
                      name="thumbnailUrl"
                      fullWidth
                      value={article.article.thumbnailUrl}
                      onChange={(e) => {
                        const updatedArticles = userProfileArticles.map((a) => {
                          if (a.id === article.id) {
                            return {
                              ...a,
                              article: {
                                ...a.article,
                                thumbnailUrl: e.target.value,
                              },
                            };
                          }
                          return a;
                        });
                        setUserProfileArticles(updatedArticles);
                      }}
                      InputProps={{ style: { color: "white" } }} // Input text color
                      InputLabelProps={{ style: { color: "white" } }} // Label color
                    />

                    <TextField
                      style={{ marginBottom: 30 }}
                      label="Description"
                      variant="outlined"
                      name="description"
                      multiline
                      rows={3}
                      fullWidth
                      value={article.article.description}
                      onChange={(e) => {
                        const updatedArticles = userProfileArticles.map((a) => {
                          if (a.id === article.id) {
                            return {
                              ...a,
                              article: {
                                ...a.article,
                                description: e.target.value,
                              },
                            };
                          }
                          return a;
                        });
                        setUserProfileArticles(updatedArticles);
                      }}
                      InputProps={{ style: { color: "white" } }} // Input text color
                      InputLabelProps={{ style: { color: "white" } }} // Label color
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Box>
            <Box marginBottom={2}></Box>
            <Button
              sx={{ color: "white", borderColor: "white" }}
              onClick={saveUserArticles}
            >
              SAVE
            </Button>
          </Box>
        ))}
      </Box>
    </form>
  );
}
