import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserCardWithBackground,UserCompanyCardWithBackground } from '../../../../components';
import { api, helpers, UserContext, constants } from "../../../../utils";
import Button from '@mui/material/Button';


const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
      </Box>
      <Box>
        {items.map((item, i) => (
          <UserCardWithBackground/>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const defaultNewUser = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  mobilePhoneNumber: '',
  timezone: constants.DEFAULT_TIMEZONE,
  roles: []
};



const Content = () => {
    const theme = useTheme();
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState(userCtx.currentUser);
    const [profileList, setProfileList] = useState([]);
    const [companyProfileList, setCompanyProfileList] = useState([]);
    const [primaryColor, setPrimaryColor] = useState("#111");
    const [secondaryColor, setSecondaryColor] = useState("#111");
    const [highlightColor, setHighlightColor] = useState("#111");
    const [bottomColor, setBottomColor] = useState("#111");
    const [companyPrimaryColor, setCompanyPrimaryColor] = useState("#111");
    const [companySecondaryColor, setCompanySecondaryColor] = useState("#111");
    const [companyHighlightColor, setCompanyHighlightColor] = useState("#111");
    const [companyBottomColor, setCompanyBottomColor] = useState("#111");
    const [userProfileDefault, setUserProfileDefaultDisplay] = useState(false);
    const [companyProfileDefault, setCompanyProfileDefaultDisplay] = useState(false); // Set false initially
    const [companyProfileThemeFetched, setCompanyProfileThemeFetched] = useState(false);
    const navigate = useNavigate();

    // Fetches the theme for the user's personal profile and updates the default status
    const getProfileTheme = () =>
        api.fetch(`profile/UserTheme/${user.id}`).then((r) => {
            if (r.data) {
                setPrimaryColor(r.data.primaryColor);
                setSecondaryColor(r.data.secondaryColor);
                setHighlightColor(r.data.highlightColor);
                setBottomColor(r.data.bottomColor);

                // Update default status based on the UserTheme API response
                setUserProfileDefaultDisplay(r.data.isDefault);
                setCompanyProfileDefaultDisplay(!r.data.isDefault); // If user profile is default, company should not be
            }
        });

    // Fetches the theme for the user's company profile and updates the default status
    const getUserCompanyProfileTheme = () =>
        api.fetch(`profile/UserCompanyTheme/${user.id}`).then((r) => {
            if (r.data) {
                setCompanyPrimaryColor(r.data.primaryColor);
                setCompanySecondaryColor(r.data.secondaryColor);
                setCompanyHighlightColor(r.data.highlightColor);
                setCompanyBottomColor(r.data.bottomColor);

                // Update default status based on the UserCompanyTheme API response
                setCompanyProfileDefaultDisplay(r.data.isDefault);
                setUserProfileDefaultDisplay(!r.data.isDefault); // If company profile is default, user should not be
                setCompanyProfileThemeFetched(true);
            } else {
                setCompanyProfileThemeFetched(false);
            }
        }).catch((error) => {
            console.error('Failed to fetch company profile theme:', error);
            setCompanyProfileThemeFetched(false);
        });

    // Fetch all user profiles
    const getAllUserProfiles = async () => {
        const response = await api.post(`profile/ProfileList/${user.id}`);
        if (response.data) {
            setProfileList(response.data);
        }
    };

    // Fetch all user company profiles
    const getAllUserCompanyProfiles = async () => {
        const response = await api.post(`profile/ProfileList/${user.id}`);
        if (response.data) {
            setCompanyProfileList(response.data);
        }
    };

    // UseEffect to fetch profiles and themes on component mount
    useEffect(() => {
        const fetchProfilesAndThemes = async () => {
            await getAllUserProfiles();
            await getAllUserCompanyProfiles();
            await getProfileTheme(); // Fetch user profile theme and default status
            await getUserCompanyProfileTheme(); // Fetch company profile theme and default status
        };

        fetchProfilesAndThemes();
    }, [user.id]);

    // Set the user's personal profile as default
    const setUserProfileDefault = () => {
        api.post(`profile/SetUserProfileDefault/${user.id}`).then((r) => {
           
                // Update the button states immediately after the successful API call
                setUserProfileDefaultDisplay(true);  // User profile set as default
                setCompanyProfileDefaultDisplay(false);  // Company profile is not default
    
                // Force a page reload to ensure state is fully reflected
                 // Reload the page after successful update
        
        }).catch((error) => {
            console.error('Failed to set user profile as default:', error);
        });
    };
    

    // Set the user's company profile as default
    const setCompanyProfileDefault = () => {
        api.post(`profile/SetCompanyProfileDefault/${user.id}`).then(() => {
            // Update the button states immediately after successful API call
            setUserProfileDefaultDisplay(false);  // Unset user profile
            setCompanyProfileDefaultDisplay(true);  // Company profile set as default
    
            // Optionally refetch the profiles, if needed for further state updates
            getAllUserProfiles();
            getAllUserCompanyProfiles();
        }).catch((error) => {
            console.error('Failed to set company profile as default:', error);
        });
    };

    return (
        <Box>
            <Box marginBottom={6}>
                <Button
                    sx={{
                        height: 54,
                        backgroundColor: userProfileDefault ? 'lightgreen' : 'darkgrey',
                        '&:hover': {
                            backgroundColor: userProfileDefault ? 'lightgreen' : 'darkgrey'
                        }
                    }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={setUserProfileDefault}
                >
                    Set User Profile as Default
                </Button>
            </Box>
            <Box>
                {profileList.map((profile, i) => (
                    <UserCardWithBackground
                        key={i}
                        primaryColor={'#fff'}
                        secondaryColor={profile.bottomColor}
                        highlightColor={profile.companyPanelColor}
                        bottomColor={profile.bottomColor}
                        user={user}
                        userProfile={profile}
                    />
                ))}
            </Box>
            {companyProfileThemeFetched && (
                <>
                    <Box marginBottom={6}>
                        <Button
                            sx={{
                                height: 54,
                                backgroundColor: companyProfileDefault ? 'lightgreen' : 'darkgrey',
                                '&:hover': {
                                    backgroundColor: companyProfileDefault ? 'lightgreen' : 'darkgrey'
                                }
                            }}
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={setCompanyProfileDefault}
                        >
                            Set Company Profile as Default
                        </Button>
                    </Box>
                    <Box>
                        {companyProfileList.map((profile2, i) => (
                            <UserCompanyCardWithBackground
                                key={i}
                                companyPrimaryColor={companyPrimaryColor}
                                companySecondaryColor={companySecondaryColor}
                                companyHighlightColor={companyHighlightColor}
                                companyBottomColor={companyBottomColor}
                                user={user}
                                userProfile={profile2}
                            />
                        ))}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Content;