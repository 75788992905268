import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from './Container';
import { NexxLogoWhite } from '../assets/images';
import { Route, Routes, useNavigate } from "react-router-dom";
import { api, helpers, UserContext, constants } from "../utils";


const mock = {
  avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
  name: 'Clara Bertoletti',
  isVerified: true,
  title: 'Paradigm Technician',
  followers: 84,
  href: '#',
  location: 'Milan, Italy',
  website: 'www.example.com',
  email: 'clara.bertoletti@example.com',
};

const UserCardWithBackground =  ({ primaryColor, secondaryColor, highlightColor, bottomColor, profileTheme, userProfile }) => {
  const profileNumber = userProfile.profileNumber;
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(userCtx.currentUser);
    console.log(userProfile, "USER PROFILE On Card")
  }, [userCtx.currentUser]);


  return (
    <Box
      sx={{
        background: 'transparent',
        borderRadius:'10px',
        borderWidth:'10px',
        borderStyle:'solid',
        borderColor:`${highlightColor}`,
        backgroundImage: `linear-gradient(180deg, ${primaryColor} 40%, ${secondaryColor} 60%, ${bottomColor} 0%)`,
        marginBottom:5
      }}
    >
      {/* <Button 
           style={{zIndex:4,margin:7, borderRadius:'4px',
           borderWidth:'1px',
           borderStyle:'solid'}}
            onClick={() => {
              navigate(`/account/theme`, {
                state: {
                  primaryColor,
                  secondaryColor,
                  highlightColor,
                  bottomColor,
                  userProfile,
                  profileTheme:profileTheme,
                  userId: userProfile.userId,
                  profileNumber:profileNumber
                }
              });
            }}
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              sx={{
                alignSelf: 'flex-end',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.divider,
                paddingX: 2,
              }}
            >
              Edit
            </Button> */}
    <Container  maxWidth={800}>
    <Button 
  onClick={() => {
    // this.props.history.push(`/${userProfile.userId}/${userProfileId}`)
    navigate(`/profile/${userProfile.userId}/${profileNumber}`, {
      state: {
        primaryColor,
        secondaryColor,
        highlightColor,
        bottomColor,
        userProfile,
        profileTheme:profileTheme,
        userId: userProfile.userId,
        profileNumber:profileNumber
      }
    });
  }}
>
        {/* <Button onClick={()=>navigate(`/profile/${user.id}`)}> */}
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        
          {/* <Avatar
            src={user.photoUrl}
            variant={'circular'}
            sx={{
              width: { xs: theme.spacing(16), sm: theme.spacing(20) },
              height: { xs: theme.spacing(16), sm: theme.spacing(20) },
              border: `8px solid ${highlightColor}`,
            }}
          /> */}
          <Card
            sx={{
              p: { xs: 2, md: 4 },
              backgroundColor:'#000',
              marginTop: theme.spacing(-10),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 1,
              height: 1,
            }}
          >
          
            <Box marginTop={3}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                marginTop={2}
              >
                <Typography fontWeight={700} color={'#fff'} variant={'h4'}>
                  {user.firstName} {user.lastName}
                </Typography>
               
              </Box>
              <Typography
                color={'text.secondary'}
                variant={'h6'}
                align={'center'}
              >
                                  {/*{user?.company} - {user?.title}*/}
                Personal Card
              </Typography>
              <Stack  marginTop={2} marginBottom={1} width={1} alignItems={'center'}>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={{ xs: 1, md: 2 }}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                     <Box
                
                component={'img'}
                src={ NexxLogoWhite}
                width={.5}
              /> 
                  </Box>
                    
                  
                </Stack>
                
              </Stack>
            </Box>
          </Card>
          
        </Box>
        </Button>
      </Container>
    </Box>
  );
};

export default UserCardWithBackground;
