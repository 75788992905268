/* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { NexxLogoBlack } from '../../assets/images';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Qr from '../../components/Qr'
import Fade from '@mui/material/Fade';
import { QrCode } from '@mui/icons-material';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import "aos/dist/aos.css";
// import AppBar from '@mui/material/AppBar';
// import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from '../../components/Container';
// import TopNav from 'components/TopNav';

import { Sidebar, Footer } from './components';

import pages from '../navigation';
import { NexxLogoWhite } from '../../assets/images';

const ProfileLayout = ({ children, bgcolor = 'transparent', bottomColor, handleDownload, useWhiteText }) => {
    const theme = useTheme();
    const [openModal, setOpenModal] = useState(false);
    const [qrCodeText, setQrCodeText] = useState('');
    const [imageSrc, setImageSrc] = useState('https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Facebook_icon.svg/2048px-Facebook_icon.svg.png');
    const [imageSize, setImageSize] = useState(50);
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [openSidebar, setOpenSidebar] = useState(false);


    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    // const handleSidebarOpen = () => {
    //   setOpenSidebar(true);
    // };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const open = isMd ? false : openSidebar;

    return (
        <Box>
            {/* <Box bgcolor={bgcolor} position={'relative'} zIndex={theme.zIndex.appBar}>
                <Container paddingTop={'20px !important'} paddingBottom={'0 !important'}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={1}
                    >
                        {useWhiteText ? (
                            <Box
                                display={'flex'}
                                component="a"
                                href="/"
                                title=" "
                                width={{ xs: 100, md: 120 }}
                            >
                                <Box
                                    style={{ paddingLeft: '1rem' }}
                                    component={'img'}
                                    src={NexxLogoWhite}
                                    height={1}
                                    width={1}
                                />
                            </Box>
                        ) : (
                            <Box
                                display={'flex'}
                                component="a"
                                href="/"
                                title=" "
                                width={{ xs: 100, md: 120 }}
                            >
                                <Box
                                    style={{ paddingLeft: '1rem' }}
                                    component={'img'}
                                    src={NexxLogoBlack}
                                    height={1}
                                    width={1}
                                />
                            </Box>
                        )}
                        <Box sx={{ flexGrow: 1 }} />
                        <Box display={'flex'} alignItems={'center'}>
                            <Box>
                                {useWhiteText ? (
                                    <Button className='p-0' onClick={handleDownload}>
                                        <SystemUpdateIcon style={{ color: 'white', fontSize: '3em' }} />
                                    </Button>
                                ) : (
                                    <Button className='p-0' onClick={handleDownload}>
                                        <SystemUpdateIcon style={{ color: 'black', fontSize: '3em' }} />
                                    </Button>
                                )}
                            </Box>
                            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                <Button className='p-0' onClick={handleOpenModal}>
                                    <QrCode style={{ color: 'white', fontSize: '3em' }} />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box> */}

            <main style={{ backgroundColor: bottomColor }}>
                {children}
            </main>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="qr-code-modal-title"
                aria-describedby="qr-code-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        background: '#fff',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        width: '400px',
                        maxWidth: '80%',
                    }}
                >
                    <Box id="qr-code-modal-description" mt={2}>
                        <Qr/>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

ProfileLayout.propTypes = {
    children: PropTypes.node,
    colorInvert: PropTypes.bool,
    bgcolor: PropTypes.string,
};

export default ProfileLayout;
