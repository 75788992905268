import React, { useState, useContext, useEffect } from "react";
import * as yup from "yup";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Formik, useFormik } from "formik";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Container,
  Typography,
  TablePagination,
  Toolbar,
  MenuItem,
  Input,
  Box,
  TextField,
  Snackbar,
  Alert,
  Grid,
  TableSortLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";
import Select from "react-select";
import { alpha, styled } from "@mui/material/styles";
import _ from "lodash";
import { visuallyHidden } from "@mui/utils";
import { api, helpers, UserContext,constants } from "../../utils";
import NumberFormat from "react-number-format";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "role", numeric: false, disablePadding: true, label: "Role" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: "bold",
              padding: "8px 16px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={{ visuallyHidden: true }} />
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};


const CompanyUserList = () => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [order, setOrder] = useState("asc");
  const [roles, setRoles] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [message, setMessage] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    title: "",
    mobilePhoneNumber: "",
    roleId: null,
    roles: [],
  });

  const [paginatedList, setPaginatedList] = useState({
    list: [],
    totalPages: 0,
    pageNumber: 1,
  });

  const [filters, setFilters] = useState([
    { filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" },
  ]);

  const validationSchema = yup.object({
    fullName: yup
      .string()
      .trim()
      .min(2, "Please enter a valid name")
      .max(50, "Please enter a valid name")
      .required("Please specify your first name"),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required."),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit,
  });

  const fetchRoles = async () => {
    try {
      const response = await api.post('UserAdmin/GetAllRoles');
      const roleOptions = response.data.map((role) => ({
        value: role.id,
        label: role.name,
        typeOfUserRole: role.typeOfUserRole,
      }));
      setRoles(roleOptions);
    } catch (error) {
      console.error('Failed to fetch roles:', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchRoles();
    }
  }, [open]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRoleChange = (event) => {
    const roleId = parseInt(event.target.value, 10);
    const selectedRoleObject = roles.find((role) => role.value === roleId);

    if (selectedRoleObject) {
      setSelectedRole({
        ...selectedRoleObject,
        typeOfUserRole: selectedRoleObject.value,
      });

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        roleId: selectedRoleObject.value,
        roles: [
          {
            userId: selectedUser?.id,
            typeOfUserRole: selectedRoleObject.value,
            roleName: selectedRoleObject.label,
          },
        ],
      }));
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const handleOpen = (user) => {
    setSelectedUser(user);
    setUserDetails({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      company: user.company,
      title: user.title,
      mobilePhoneNumber: user.mobilePhoneNumber,
      roleId: user.roleId,
      roles: user.roles || [],
    });
    setOpen(true);
  };

  const handlePhoneNumberChange = (value) => {
    const inputValue = value.replace(/\D/g, "");
    const formattedValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(
      3,
      6
    )}-${inputValue.slice(6, 10)}`;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      mobilePhoneNumber: formattedValue,
    }));
    if (inputValue.length === 10 || inputValue.length === 0) {
      setPhoneNumberError("");
    } else {
      setPhoneNumberError("Phone number must be 10 digits.");
    }
  };

  const handleChange = (fieldName, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const nameFilter = newFilters.find((f) => f.filterName === "Name");
      if (nameFilter) {
        nameFilter.value = value;
      } else {
        newFilters.push({ filterName: "Name", value });
      }
      return newFilters;
    });
    setPageNumber(0);
  };

  const createPayload = () => {
    let payload = {
      sortField: orderBy || "defaultSortField",
      sortDirection: order || "ASC",
      pageNumber: pageNumber + 1,
      maxResults: rowsPerPage,
      name: filters.find((f) => f.filterName === "Name")?.value || "",
      email: "",
      idList: [],
      memberOfRoles:
        filters.find((f) => f.filterName === "MemberOfRoles")?.values || [],
      username: "",
    };

    filters.forEach((filter) => {
      if (filter.value !== undefined && filter.value !== null) {
        payload[filter.filterName] = filter.value;
      }
    });

    return payload;
  };

  function onSubmit() {
    let warnings = [];

    // Validate First Name
    if (!userDetails.firstName || userDetails.firstName.trim().length < 2) {
        warnings.push("Please provide a valid first name.");
    }

    // Validate Last Name
    if (!userDetails.lastName || userDetails.lastName.trim().length < 2) {
        warnings.push("Please provide a valid last name.");
    }

    // Validate Email
    if (!userDetails.email || !helpers.emailIsValid(userDetails.email)) {
        warnings.push("Please provide a valid email.");
    }

    // Validate Selected Role
    if (!selectedRole || !selectedRole.value) {
        warnings.push("Please select a role.");
    }

    // If there are warnings, display them
    if (warnings.length > 0) {
        setMessage({
            text: warnings.join(" "),
            flavor: "alert-danger",
        });
        return;
    } else {
        setMessage(null);
    }

    // Prepare the updated user object
    const updatedUser = {
        ...selectedUser, // Copy over selectedUser
        ...userDetails,  // Merge with userDetails to ensure all fields are included
        roles: [{
            userId: selectedUser?.id || userDetails.id,
            roleId: selectedRole?.value,
            typeOfUserRole: selectedRole?.typeOfUserRole,
            roleName: selectedRole?.label || selectedRole?.typeOfUserRole?.GetEnumDescription(),
        }], // Ensure `roles` is mapped correctly
    };

    // Make API request to save the updated user
    api.post("UserAdmin/SaveUser", updatedUser)
        .then((response) => {
            if (response.data.success) {
                setMessage({
                    text: "Your profile has been updated successfully!",
                    flavor: "alert-success",
                });
                setSnackbarOpen(true);
                fetchPaginatedData(); // Refresh the list after successful save
                setTimeout(() => {
                    handleClose(); // Close modal after 1 second
                }, 1000);
            } else {
                setMessage({ text: response.data.message, flavor: "alert-danger" });
            }
        })
        .catch(helpers.catchHandler); // Handle any errors
  }
  
  function handleResetUser() {
    const updatedUser = {
        ...selectedUser,
        ...userDetails,
        roles: [{
            userId: selectedUser?.id || userDetails.id,
            roleId: 2,
        }],
    };

    api.post("Public/ResetDefaultUser", updatedUser)
        .then((response) => {
            if (response.data.success) {
                setMessage({
                    text: "This user has been successfully reset",
                    flavor: "alert-success",
                });
                setSnackbarOpen(true);
                fetchPaginatedData();
                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                setMessage({ text: response.data.message, flavor: "alert-danger" });
            }
        })
        .catch(helpers.catchHandler);
}

  const fetchPaginatedData = () => {
    api
      .post("UserAdmin/List", createPayload())
      .then((response) => {
        setPaginatedList({
          list: response.data.list,
          totalPages: response.data.totalPages,
          pageNumber: response.data.pageNumber,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchPaginatedData();
  }, [order, orderBy, pageNumber, rowsPerPage, filters]);

  return (
    <Container
      sx={{
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "0",
        minHeight: "100vh",
      }}
    >
      <Paper sx={{ width: "100%", mb: 2, padding: "0", boxShadow: 3 }}>
        <Toolbar sx={{ mb: 2, padding: 0 }}>
          <Typography sx={{ flex: "1 1 100%" }} variant="h6" component="div">
            Company Users
          </Typography>
          <Input
            type="text"
            placeholder="Search by name"
            onChange={handleSearchChange}
            sx={{ ml: "auto", mr: "1rem", width: "300px" }}
          />
        </Toolbar>
        <TableContainer style={{ padding: 0 }}>
          <Table sx={{ minWidth: 750, padding: 0 }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
           <TableBody>
  {paginatedList.list.map((user) => (
    <StyledTableRow key={user.id}>
      <TableCell sx={{ padding: "8px 16px", color: "teal" }}>  {/* Set color to 'teal' or whichever color */}
        {user.fullName}
      </TableCell>
      <TableCell sx={{ padding: "8px 16px", color: "black" }}>
        {user.roles.map((role, index) => (
          <div key={index}>{role.roleName}</div> 
        ))}
      </TableCell>
      <TableCell sx={{ padding: "8px 16px", color: "teal" }}> {/* Match the color here */}
        <Button onClick={() => handleOpen(user)} sx={{ color: "inherit" }}> {/* Inherit the color from TableCell */}
          View Details
        </Button>
      </TableCell>
    </StyledTableRow>
  ))}
</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={paginatedList.totalPages * rowsPerPage}
          page={pageNumber}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() =>
            `Page ${paginatedList.pageNumber} of ${paginatedList.totalPages}`
          }
          sx={{ mt: 2 }}
        />
        <Modal open={open} onClose={handleClose}>
          <Paper style={{ margin: "auto", padding: 20, maxWidth: 600 }}>
            <Typography variant="h6">Edit User Information</Typography>
            <IconButton 
            aria-label="delete user" 
            onClick={handleResetUser} 
            sx={{ 
                float: 'right',
                color: 'red' 
            }}
        >
            <PersonOffIcon />
        </IconButton>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2, color: "black" }}
                    fontWeight={700}
                  >
                    First Name *
                  </Typography>
                  <TextField
                    variant="outlined"
                    name={"firstName"}
                    fullWidth
                    value={userDetails.firstName || ""}
                    onChange={(event) =>
                      handleChange(event.target.name, event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2, color: "black" }}
                    fontWeight={700}
                  >
                    Last Name *
                  </Typography>
                  <TextField
                    variant="outlined"
                    name={"lastName"}
                    fullWidth
                    value={userDetails.lastName || ""}
                    onChange={(event) =>
                      handleChange(event.target.name, event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2, color: "black" }}
                    fontWeight={700}
                  >
                    Email *
                  </Typography>
                  <TextField
                    variant="outlined"
                    name={"email"}
                    fullWidth
                    value={userDetails.email || ""}
                    onChange={(event) =>
                      handleChange(event.target.name, event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2, color: "black" }}
                    fontWeight={700}
                  >
                    Company
                  </Typography>
                  <TextField
                    variant="outlined"
                    name={"company"}
                    fullWidth
                    value={userDetails.company || ""}
                    onChange={(event) =>
                      handleChange(event.target.name, event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2, color: "black" }}
                    fontWeight={700}
                  >
                    Title
                  </Typography>
                  <TextField
                    variant="outlined"
                    name={"title"}
                    fullWidth
                    value={userDetails.title || ""}
                    onChange={(event) =>
                      handleChange(event.target.name, event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant={'subtitle2'} sx={{ marginBottom: 2, color: 'black' }} fontWeight={700}>
                    Mobile Phone Number *
                  </Typography>
                  <NumberFormat
                    customInput={TextField}
                    format="(###) ###-####"
                    mask="_"
                    name="mobilePhoneNumber"
                    fullWidth
                    error={phoneNumberError.length > 0}
                    helperText={phoneNumberError}
                    value={userDetails.mobilePhoneNumber}
                    onValueChange={(values) =>
                      handlePhoneNumberChange(values.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
  <Typography variant={'subtitle2'} sx={{ marginBottom: 2, color: 'black' }} fontWeight={700}>
    Role *
  </Typography>
  <RadioGroup
    aria-label="role"
    name="role"
    value={userDetails.roleId || ""}
    onChange={handleRoleChange} // Handle role changes
  >
    {roles?.map((role) => (
      <FormControlLabel
        key={role.value}
        value={role.value}
        control={<Radio />}
        label={role.label}
      />
    ))}
  </RadioGroup>
</Grid>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={3000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Your profile has been updated successfully!
                  </Alert>
                </Snackbar>
              </Grid>
              <Box display="flex" justifyContent="flex-end" marginTop={4}>
                <Button
                  onClick={() => onSubmit()}
                  component={"a"}
                  variant="contained"
                  color="customColor"
                  size="large"
                >
                  Save Changes
                </Button>
              </Box>
            </form>
          </Paper>
        </Modal>
      </Paper>
    </Container>
  );
};

export default CompanyUserList;